.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  margin-top: 15px;
  border-radius: 40px;
  text-align: center;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 6px;
  padding: 0;
}

.button__bar li button {
  border: 3px solid #c4c4c4;
  /* background: #a53692; */
  border-collapse: separate;
  border-spacing: 15px;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
}

.button__bar li.slick-active button {
  background-color: #fff;
}

.button__bar li button:hover,
.button__bar li button:focus {
  background: #fff;
  outline: 0;
}

/* .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
} */

.arrow {
  position: absolute;
  color: #000000;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
}

.nextArrow {
  top: 46%;
  right: -5px;
  display: block;
}

.prevArrow {
  top: 46%;
  left: -5px;
  display: block;
}
